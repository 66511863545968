import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import { UX2, components } from '@wsb/guac-widget-core';

const { Link } = components;

const imageMaxWidth = 600;

const Caption = ({ caption }) => {
  return (
    <UX2.Element.Block
      category='accent'
      section='overlay'
      style={{
        background: 'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75))',
        padding: 'small',
        paddingTop: 'medium',
        position: 'absolute',
        width: '100%',
        bottom: '0px',
        ['@md']: {
          padding: 'medium',
          paddingTop: 'large'
        }
      }}
    >
      <UX2.Element.Text tag='figcaption' richtext>
        { caption }
      </UX2.Element.Text>
    </UX2.Element.Block>
  );
};

Caption.propTypes = {
  caption: PropTypes.string
};

const Image = ({ uniformHeight, imageData, caption, externalLink, ...props }) => {
  const [viewRef, inView] = useInView({ threshold: 0 });
  const styles = {
    opacity: inView ? 1 : 0,
    display: 'block',
    transition: 'opacity .5s ease-in-out',
    width: uniformHeight ? 'auto' : '95vw',
    height: uniformHeight ? '38vh' : 'auto',
    maxHeight: '100%',
    ['@md']: {
      width: uniformHeight ? 'auto' : '35vw',
      height: uniformHeight ? '50vh' : 'auto'
    }
  };

  const ImageComponent = (
    <UX2.Element.Image
      { ...props }
      imageData={{ ...imageData, outputWidth: imageMaxWidth }}
      style={ styles }
    />
  );

  // A bit of a hack because we can't access the dom for UX2 via refs
  // this is to allow the intersection observer to detect when the component is inview

  return (
    <figure
      ref={ viewRef }
      style={{
        margin: 0,
        padding: 0,
        flexShrink: 0,
        position: 'relative',
        marginRight: uniformHeight ? '16px' : '24px'
      }}
    >
      { externalLink ? <Link linkData={ externalLink }>{ ImageComponent }</Link> : ImageComponent }
      { caption && <Caption caption={ caption } /> }
    </figure>
  );
};

export default Image;

Image.propTypes = {
  caption: PropTypes.string,
  uniformHeight: PropTypes.bool,
  imageData: PropTypes.object,
  externalLink: PropTypes.object
};

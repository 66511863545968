import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { UX2 } from '@wsb/guac-widget-core';
import { getGalleryDataAid, getGalleryDataRouteProps } from '../../common/constants/dataAids';
import Image from './Image';

const styles = {
  box: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollBehavior: 'smooth'
  },
  indicator: {
    display: 'none',
    height: '2px',
    width: '24px',
    background: 'currentColor',
    margin: '4px',
    marginTop: 'medium',
    borderRadius: '2px',
    cursor: 'pointer',
    position: 'relative',
    [':after']: {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: '-16px',
      bottom: '-16px',
      left: '0px',
      right: '0px',
      padding: '4px'
    },
    ['@md']: {
      display: 'block'
    }
  }
};

const spacerStyles = {
  flexShrink: 0,
  width: '24px',
  minHeight: '1px',
  ['@md']: {
    width: 'calc((100vw / 2) - (984px / 2) + 24px)'
  },
  ['@lg']: {
    width: 'calc((100vw / 2) - (1160px / 2) + 24px)'
  },
  ['@xl']: {
    width: 'calc((100vw / 2) - (1280px / 2) + 24px)'
  }
};

const Spacer = ({ style }) => <UX2.Element.Block style={{ ...spacerStyles, ...style }} />;

Spacer.propTypes = {
  style: PropTypes.object
};

function Gallery8({ galleryImages, uniformWidthOrHeight }) {
  const ref = useRef(null);
  const [panelCount, setPanelCount] = useState(0);
  const [currentPanel, setCurrentPanel] = useState(0);

  // set indicator for current panel
  const handleScroll = useCallback(
    ({ target: { scrollLeft, scrollWidth, offsetWidth } }) => {
      const panels = panelCount - 1;
      const scrollableDistance = scrollWidth - offsetWidth;
      const distance = scrollableDistance / panels;
      const index = Math.min(Math.floor(scrollLeft / distance), panels);
      // only update if its a new index
      if (currentPanel !== index) {
        setCurrentPanel(index);
      }
    },
    [panelCount, currentPanel]
  );

  // update the number of panels on resize
  useEffect(() => {
    const setPanels = () => {
      if (ref.current) {
        const totalPanels = Math.ceil(ref.current.scrollWidth / ref.current.offsetWidth);
        if (panelCount !== totalPanels) {
          setPanelCount(totalPanels);
        }
      }
    };

    const images = Array.from(document.querySelectorAll('.gallery-8-img'));
    window.requestAnimationFrame(setPanels);
    window.addEventListener('resize', setPanels);
    images.forEach(img => {
      img.addEventListener('load', setPanels);
    });
    return () => {
      window.removeEventListener('resize', setPanels);
      images.forEach(img => {
        img.removeEventListener('load', setPanels);
      });
    };
  }, [galleryImages, panelCount]);

  const uniformHeight = uniformWidthOrHeight === 'uniformHeight';

  return (
    <React.Fragment>
      <div ref={ ref } onScroll={ handleScroll } style={ styles.box }>
        <Spacer />
        { galleryImages.map(({ image, caption, externalLink }, i) => {
          const imageProps = {
            uniformHeight,
            caption,
            externalLink,
            'className': 'gallery-8-img',
            'style': { cursor: 'pointer' },
            'imageData': image,
            'data-aid': getGalleryDataAid(i),
            ...getGalleryDataRouteProps(i, { isImage: true })
          };

          return <Image key={ i } { ...imageProps } />;
        }) }
        <Spacer style={{ width: uniformHeight ? '8px' : 0 }} />
      </div>
      <UX2.Element.Container>
        { panelCount > 1 && (
          <UX2.Component.HorizontalBox align='middle'>
            { Array.from({ length: panelCount }).map((_, i) => {
              return (
                <UX2.Element.Block
                  key={ i }
                  style={{
                    ...styles.indicator,
                    color: i === currentPanel ? 'highContrast' : 'ultraLowContrast'
                  }}
                  onClick={ () => {
                    ref.current.scrollLeft = (ref.current.scrollWidth / panelCount) * i;
                  } }
                />
              );
            }) }
          </UX2.Component.HorizontalBox>
        ) }
      </UX2.Element.Container>
    </React.Fragment>
  );
}

export default Gallery8;

Gallery8.propTypes = {
  id: PropTypes.string,
  category: PropTypes.string,
  device: PropTypes.string,
  galleryImages: PropTypes.array.isRequired,
  renderAsThumbnail: PropTypes.bool,
  renderMode: PropTypes.string,
  section: PropTypes.string,
  size: PropTypes.string,
  staticContent: PropTypes.object,
  uniformWidthOrHeight: PropTypes.string
};
